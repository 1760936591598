<template>
  <div class="mb-3">
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>

    <div class="checkbox-group">
      <slot name="before" />
      <div class="form-check">
        <input
          :id="`${prefix}_select_all`"
          type="checkbox"
          class="form-check-input"
          :checked="isAllSelected"
          :disabled="disabled"
          @change="handleSelectAllChange"
        />
        <label
          class="form-check-label"
          :for="`${prefix}_select_all`"
        >
          Select all
        </label>
      </div>
      <div
        v-for="(option, index) in options"
        :key="index"
        class="form-check"
      >
        <input
          :id="`${prefix}_${index}`"
          type="checkbox"
          class="form-check-input"
          :value="option.key"
          :checked="internalValue.includes(option.key)"
          :disabled="disabled"
          @change="handleCheckboxChange(option.key)"
        />
        <label
          class="form-check-label"
          :for="`${prefix}_${index}`"
        >
          {{ option.value }}
        </label>
      </div>
      <slot name="after" />
    </div>

    <div
      v-if="hasErrors"
      class="invalid-feedback d-block"
    >
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { Tooltip } from 'bootstrap';

export default {
  props: [
    'modelValue',
    'label',
    'description',
    'errors',
    'options',
    'disabled',
  ],
  emits: ['update:modelValue'],
  data() {
    return {
      prefix: _.uniqueId('checkbox_'),
      internalValue: this.modelValue || [],
    };
  },
  computed: {
    hasErrors() {
      return this.errors && this.errors.length > 0;
    },
    isAllSelected() {
      return this.internalValue.length  === this.options.length;
    }
  },
  watch: {
    modelValue(newValue) {
      this.internalValue = newValue || [];
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  },
  methods: {
    handleSelectAllChange() {
      if (this.isAllSelected) {
        this.internalValue = [];
      } else {
        this.internalValue = this.options.map((option) => option.key);
      }
      this.$emit('update:modelValue', this.internalValue);
    },
    handleCheckboxChange(key) {
      let newValue = [...this.internalValue];
      if (newValue.includes(key)) {
        newValue = newValue.filter((item) => item !== key);
      } else {
        newValue.push(key);
      }
      this.internalValue = newValue;
      this.$emit('update:modelValue', this.internalValue);
    }
  }
};
</script>

<style scoped>
.checkbox-group {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 4px;
}

.form-check {
  margin-bottom: 5px;
}

.form-check-input {
  margin-right: 8px;
}
</style>
